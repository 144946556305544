<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="80px" input-width="width:60%;">
      <el-form-item label="模板名称">
        <el-input v-model="form.name" placeholder="请输入模板名称"></el-input>
      </el-form-item>
      <el-form-item label="计费方式">
        <el-radio-group v-model="form.chargeMode" @change="changeMode">
          <el-radio label="1">按件数</el-radio>
          <el-radio label="2">按重量</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-table :data="tableData">
          <el-table-column v-for="(item, index) in columns" :key="item.prop" :prop="item.prop" :lable="item.label"></el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">指定可配送区域和运费</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>export default {
  name: "TemplateAdd",

  data() {
    return {
      columns: [{
        label: '可配送区域',
        prop: 'areas',
        type: 'text'
      },{
        label: '首件(个)',
        prop: 'firstUnit',
        type: 'number'
      },{
        label: '运费(元)',
        prop: 'freight',
        type: 'money'
      },{
        label: '续件(个)',
        prop: 'renewUnit',
        type: 'number'
      },{
        label: '续费(元)',
        prop: 'renewFreight',
        type: 'number'
      }],

      // columnsWeight: [{
      //   label: '可配送区域',
      //   prop: 'areas',
      //   type: 'text'
      // },{
      //   label: '首重(Kg)',
      //   prop: 'firstUnit',
      //   type: 'number'
      // },{
      //   label: '运费(元)',
      //   prop: 'freight',
      //   type: 'money'
      // },{
      //   label: '续重(Kg)',
      //   prop: 'renewUnit',
      //   type: 'number'
      // },{
      //   label: '续费(元)',
      //   prop: 'renewFreight',
      //   type: 'number'
      // }],
      tableData: [],
      form: {
        name: '',
        chargeMode: '1'
      }
    };
  },
  methods: {
    /**
     * 改变计费方式
     */
    changeMode(e) {
      console.log('e',e)
    },
    onSubmit() {

    }
  }
};
</script>